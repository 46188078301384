import React from "react";
import "../App.css";
import { Link } from "react-router-dom";
import Tada from 'react-reveal/Tada';

import BotonUser from "../userBotones/BotonUser";
import BotonUser2 from "../userBotones/BotonUser2";
import BotonUserWeb from "../userBotones/BotonUserWeb";
import logohome from "../assets/imagenes/fans-recoleta/especificas/logo-home.png"


class Sidebar1 extends React.Component {


  render () {
    // const closeMenu = () => {
    //   document.querySelector(".sidebar").classList.remove("open");
    // };

    return (
      <React.Fragment>
        
                <Tada >
        <Link to="/" className="logo-web lista-navbar">
          <img
           src={logohome}
            className="icon-logo"
            alt="Sólo Fanáticos"
          />
        </Link>
        </Tada>
     
      <div className="lista-navbar">

        
        <div className="lista-sidebar-n2222" >
        <BotonUserWeb/>
        </div>
        <Link to="/como-participar-1" id="juegos" className="lista-sidebar-n" >
        ¿Cómo participar?
        </Link>
     
        <a href="/#califica" className="lista-sidebar-n" >
        Calificá
        </a>
        <a href="/#graficos" className="lista-sidebar-n" >
        Mejores calificados
        </a>
        <a href="/#contacto" className="lista-sidebar-n">
          Contacto
        </a>
        <a href="" className="lista-sidebar-n">
        Términos y Condiciones
        </a>
        <a href="" className="lista-sidebar-n">
        Política de Privacidad
        </a>
       
        <div className="lista-sidebar-n">
        <BotonUser2/>
        </div>

        
      </div>
      </React.Fragment>
   );
  }
}
  
export default Sidebar1;
