import React from "react";
import '../App.css';
import { Link } from "react-router-dom";
import BotonUser from "../userBotones/BotonUser";




class Sidebar2 extends React.Component {

  
    render () {
      const closeMenu = () => {
        document.querySelector(".sidebar").classList.remove("open");
      };

      return (
        
        <div className="lista-20202">
                  
          <Link style={{background:"none"}} className="lista-sidebar" onClick={closeMenu} to="/signin" id="Juegos" > <BotonUser /></Link> 
          <hr className="hr2"/> 
          <Link  onClick={closeMenu} to="/como-participar-1" className="lista-sidebar">¿Cómo participar?</Link>
          <hr className="hr2"/> 
          <a href="/#califica" onClick={closeMenu}  className="lista-sidebar">Calificá</a>
          <hr className="hr2"/> 
          <a href="/#graficos"onClick={closeMenu}  className="lista-sidebar">Mejores calificados</a>
          <hr className="hr2"/> 
          <a href="/#contacto"  onClick={closeMenu}   className="lista-sidebar">Contacto</a>
          <hr className="hr2"/> 
          <a href=""  onClick={closeMenu}   className="lista-sidebar">Términos y Condiciones</a>

          <hr className="hr2"/> 
          <a href=""  onClick={closeMenu}   className="lista-sidebar">Política de Privacidad</a>


          

        
        </div>
      );
    }
  }
  export default Sidebar2;