import React from 'react';
import "../App.css";
import Zoom from 'react-reveal/Zoom';





function Footer() {

  return (
    <div className="footer">

      
      <Zoom bottom>
      <div className="footer-redes">


      

<div className="whatsapp-c">

<p className=" p2">Desarrollado por</p>
</div>
</div>
<div className="footer-derechos">
  <a href="https://www.emprendimientosvirtuales.com.py" target='_banck'>
<b className="p2" style={{marginBottom:'1rem',marginTop:'-1rem'}}>Emprendimientos Virtuales S.A. y </b>
</a>
<a href="https://www.dareagenciadigital.com" target='_banck'>
<b className="p2" style={{marginBottom:'1rem',marginTop:'-1rem'}}>Dare Agencia Digital</b>
</a>
</div>
        </Zoom>
     
      
    </div>
  );
}

export default Footer;
