import React, { useEffect, useState } from "react";
import Formacion1 from "../alineaciones/Formacion1";
import Slider from "react-slick";
import { fetchAuspiciantes } from "../action/especificos/AuspicianteActions";
import { fetchAuspiciantes2 } from "../action/especificos/AuspicianteActions2";
import "../App.css";
import GraficosVotaciones from "../componentes.home/GraficosVotaciones";
import SolidButton from "../componentes/buttons/SolidButton";
import ContactoHome2 from "./ContactoHome2";
import { useDispatch, useSelector } from "react-redux";
import TablaComponent from "../componentes.home/TablaComponent";
import { Link } from "react-router-dom";
import { Auspiciantes } from "../componentes.home/Auspiciantes";

function Home2() {
  const [load1, setLoad1] = useState(true);
  const [load2, setLoad2] = useState(true);
  const config_server = JSON.parse(localStorage.getItem("configServer"));
  const dispatch = useDispatch();
  const auspiciantes = useSelector((state) => state.auspiciantes);
  const auspiciantes2 = useSelector((state) => state.auspiciantes2);
  const encuentros = useSelector((state) => state.products);
  const scrollToTop = () =>{
    window.scrollTo({
        top: 0, 
        behavior: 'smooth'
    });
};
  useEffect(() => {
    scrollToTop();
    if (load1) {
      const loadAuspiciantes = () => dispatch(fetchAuspiciantes());
      
      loadAuspiciantes();
      setLoad1(false);
    }
    if (load2) {
      const loadAuspiciantes2 = () => dispatch(fetchAuspiciantes2());
      loadAuspiciantes2();
      setLoad2(false);
    }
  }, [auspiciantes, dispatch, load1, load2]);
  const config = {
    dots: false,
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: -2,
    cssEase: "linear",
    arrows: false,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: -2,
          infinite: true,
          dots: false,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: -2,
          infinite: true,
          dots: false,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: -2,
          infinite: true,
          dots: false,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 2000,
        },
      },
    ],
  };
  const config2 = {
    dots: false,
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 2,
    cssEase: "linear",
    arrows: false,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          autoplay: true,
          speed: 1000,
          autoplaySpeed: 2000,
        },
      },
    ],
  };
  const graficos = () => {
    return (
      <div className="graficos">
        <GraficosVotaciones />
      </div>
    );
  };
  const settings2 = config2;
  const settings = config;
  console.log("ffff", encuentros.items);
  return (
    <React.Fragment>
      <br />
      <Auspiciantes />
      <TablaComponent />

      <h1 className="h1 centrar" style={{ marginTop: "3.5rem !important" }}>
      Calificá a los mejores (solo titulares)
      </h1>

      <div id="califica">
        <Formacion1 />
      </div>

      <div className="comocalificarf">
        <div className="cfff">
          <h1 className="preg">¿Cómo calificar?</h1>

          <Link to="/como-participar-1">
            <SolidButton type="red-agregar-c" txt="Ver video"></SolidButton>
          </Link>
        </div>
      </div>

      <div id="graficos" className="graficos1">
        {!auspiciantes.items ? (
          <div className="imagen-home-promociones1-oculto"></div>
        ) : (
          <Slider
            {...settings2}
            aria-hidden="true"
            style={{ marginTop: "5rem", marginBottom: "2rem" }}
          >
            {auspiciantes.items.map((auspiciante) => {
              return (
                <img
                  key={auspiciante.id_auspiciante}
                  src={`${config_server.linkImageAuspiciante}${auspiciante.imagen_auspiciante}`}
                  className="img-auspiciantes"
                  alt="Preparativos para la plataforma de Futbol Total"
                />
              );
            })}
          </Slider>
        )}
        {!auspiciantes2.items ? (
          <div className="imagen-home-promociones1-oculto"></div>
        ) : (
          <Slider
            {...settings}
            aria-hidden="true"
            // style={{ marginTop: "2rem", marginBottom: "25rem" }}
          >
            {auspiciantes2.items.map((auspiciante) => {
              return (
                <img
                  key={auspiciante.id_auspiciante}
                  src={`${config_server.linkImageAuspiciante}${auspiciante.imagen_auspiciante}`}
                  className="img-auspiciantes"
                  alt="SÓLO FANÁTICOS"
                />
              );
            })}
          </Slider>
        )}

        <br />
        <br />
      </div>
      {graficos()}

      <br />
      <br />

      <br />
      <div>
        <div id="contacto">
          <ContactoHome2 />
        </div>
      </div>
    </React.Fragment>
  );
}

export default Home2;
